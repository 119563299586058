<script lang="ts">
  import { onMount } from "svelte";
  import Masonry from "../lib/viewer/masionary"
  import { decodeId } from "../lib/viewer/utils";


  let rect: DOMRect
  let isLoading = true

  const m = Masonry<{id: string, url: string, alt: string, x: number, y: number, w: number, h: number}>([], 2)


   $: columns = Math.max(1, Math.min(4, Math.floor((rect?.width ?? 100) / 380)))
   $: columnWidth = ((rect?.width ?? 100) / columns) - 2

   $: m.setLayout(Array(columns).fill(columnWidth), 2)


   
   onMount(async () => {
    const data = await  (await fetch("/top.json")).json() as string[]

    const images = data.map(decodeId).map(img => ({
      id: img.img_id,
      url: `https://s.pixelpeep.com/tiles/${img.img_id}/`,
      alt: img.img_id,
      x: 0,
      y: 0,
      w: img.img_w,
      h: img.img_h
    }))

    m.addImages(images)

    isLoading = false
   })

</script>


<svelte:head>
  {#each $m.images as image (image.id)}
    {#if columns > 1}
    <link rel="prefetch" href={`${image.url}t_512.avif`} as="image" crossorigin="anonymous"/>
    <link rel="prefetch" href={`${image.url}t_1024.avif`} as="image" crossorigin="anonymous"/>
    {:else}
    <link rel="prefetch" href={`${image.url}t_2048.avif`} as="image" crossorigin="anonymous"/>
    {/if}
  {/each}
</svelte:head>

<div class="container mx-auto bg-gray-100 sm:rounded-lg overflow-hidden min-h-300 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-0.5 relative"
  style:min-height={`${$m.maxHeight}px`}
  class:animate-pulse={isLoading}
  bind:contentRect={rect}
>
    <div class="from-gray-300 to-gray-100 bg-gradient-to-b">
    </div>
    <div class="from-gray-300 to-gray-100 bg-gradient-to-b hidden sm:block">
    </div>
    <div class="from-gray-300 to-gray-100 bg-gradient-to-b hidden md:block">
    </div>
    <div class="from-gray-300 to-gray-100 bg-gradient-to-b hidden lg:block">
    </div>


    {#each $m.images as image (image.id)}
      <a href="/p/{image.id}" style:--y={`${image.y}px`} style:--x={`${image.x}px`} class="absolute virt" astro-prefetch>
        <picture class="">
          <source srcset={`${image.url}t_512.avif, ${image.url}t_1024.avif 2x,  ${image.url}t_2048.avif 3x`} />
          <img src={`${image.url}t_512.avif`} alt={image.alt} loading="lazy" width={image.w} height={image.h} class="bg-gray-500" crossorigin="anonymous"/>
        </picture>
      </a>
    {/each}
</div>



<style>
  .virt {
    top: var(--y);
    left: var(--x);
  }
</style>