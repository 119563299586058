import { writable } from 'svelte/store';

interface Image { w: number; h: number; }
interface Position { x: number; y: number; }

type ReturnType<T> = {
  images: T[],
  maxHeight: number
}


export default <T extends Image>(initialColumnWidths: number[], initialGap: number) => {

  // generate an array of x positions for each column
  const computeXPositions = (columnWidths: number[], gap: number) =>
    columnWidths.reduce((acc, _, i) => i ? [...acc, acc[i-1] + columnWidths[i-1] + gap] : [0], [] as number[]);


  let images: T[] = [],
      columnWidths = initialColumnWidths,
      gap = initialGap,
      currentHeights = Array(columnWidths.length).fill(0),
      xPositions = computeXPositions(columnWidths, gap),
      result: ReturnType<T & Position> = {images: [], maxHeight: 0};
  const { subscribe, set } = writable<ReturnType<T>>(result);


  const addImages = (newImages: T[]) => {
    result.images.push(...newImages.map(nextImage));
    images.push(...newImages);
    result.maxHeight = Math.max(...currentHeights);
    set(result);
  };

  const nextImage = (image: T): T & Position =>  {
    const col = currentHeights.indexOf(Math.min(...currentHeights)),
          x = xPositions[col],
          y = currentHeights[col],
          h = image.h * (columnWidths[col] / image.w);
    currentHeights[col] += h + gap;
    return {...image, x, y, h, w: columnWidths[col] } as T & Position;
  } 

  const setLayout = (newColumnWidths: number[], newGap: number) => {
    columnWidths = newColumnWidths;
    gap = newGap;
    xPositions = computeXPositions(columnWidths, gap);
    currentHeights = Array(columnWidths.length).fill(0);
    result.images = images.map(nextImage);
    result.maxHeight = Math.max(...currentHeights);
    set(result);
  };

  return { subscribe, addImages, setLayout };
};